import moment from "moment"

export const orderTextMessageTemplate = ({ zendeskID }) => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] This is to acknowledge your order. Our coordinator will update you within the day during operating hours (Mon - Fri. 8:30AM-4:30PM, Sat. 8:30AM-3:00PM) or on the next working day for any further updates. If you do not hear from us within one working day, please send an SMS to 09178669487 with your reference number ${zendeskID}. Thank you. -CarePlus`
}

export const specialOrderTextMessageTemplate = ({ zendeskID }) => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] This is to acknowledge your order. Our coordinator will update you within the day during operating hours (Mon - Fri. 8:30AM-4:30PM, Sat. 8:30AM-3:00PM) or on the next working day for any further updates. If you do not hear from us within one working day, please send an SMS to 09178669487 with your reference number ${zendeskID}. Thank you. -CarePlus`
}
