export const shouldBeDisabled = ({ formData, medicines }) => {
  return (
    medicines.length === 0 ||
    (!formData.firstName && !formData.doctorAccountName) ||
    !formData.deliveryAddress?.streetAddress ||
    formData.agreeToConsent.length === 0 ||
    formData.agreeToTermsAndConditions.length === 0 ||
    formData.submittingUserType === undefined
  )
}
